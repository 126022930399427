/*===================================================================================================
  SEONamedLinks Module
===================================================================================================*/

.seonamedlinks-module {
    padding: 2em 4em;
}


.seonamedlinks-module .column ul li {
    list-style: none;
    margin-bottom: 14px;
    font-size: 16px;
    cursor: pointer;
}

.seonamedlinks-module .count {
    font-size: 12px;
}

.seonamedlinks-module .column {
    -webkit-column-width: 220px;
    -moz-column-width: 220px;
    column-width: 220px;
}

@media (max-width: 767px) {
    .seonamedlinks-module {
        padding: 3em 2em;
    }
}
