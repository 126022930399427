body.font-Cinzel .font-primary {
    font-family: 'Cinzel', serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 400;
}

body.font-Cinzel .vehicle-link .font-primary {
    letter-spacing: .02em;
}


body.font-Cinzel .btn {
    font-size: 15px;
}

body.font-Cinzel .btn-xl {
    font-size: 16px;
}

body.font-Cinzel .btn-xlg {
    font-size: 18px;
}

body.font-Cinzel .btn-super {
    font-size: 17px;
}

body.font-Cinzel .btn-sm {
    font-size: 15px;
}

body.font-Cinzel .staff-member .staff-photo .staff-name {
    font-size: 16px;
}

body.font-Cinzel .section-title,
body.font-Cinzel .page-title-headings h2,
body.font-Cinzel .page-title-headings h1,
body.font-Cinzel .locations-section .dealer-header,
body.font-Cinzel .vehicle-title,
body.font-Cinzel .vehicle-card .vehicle-title,
body.font-Cinzel .site-logo h2,
body.font-Cinzel .hero-overlay h2,
body.font-Cinzel .section-title h2,
body.font-Cinzel .dealer-name,
body.font-Cinzel .modal-title,
body.font-Cinzel .staff-member .staff-photo .staff-name,
body.font-Cinzel .staff-member .staff-photo .staff-name span,
body.font-Cinzel .header-logo a {
    text-transform: none;
    letter-spacing: 0.05em;
    font-weight: 400 !important;
}
