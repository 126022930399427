
.staff-m1 {
    padding: 20px 0 60px;
}


/* Staff 2
===========================================================*/

.staff-m1 .staff-member {
    position: relative;
    margin-bottom: 30px;
}

.staff-m1 .staff-member .staff-photo {
    position: relative;
    vertical-align: bottom;
    max-width: 100%;
    overflow: hidden;
    min-height: 350px;
}

.staff-m1 .staff-member .staff-photo .staff-name {
    position: absolute;
    bottom: 22px;
    right: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 14px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background-color: #0083ae; /* TODO: Consider removing other colors in this css file that don't appear to be used */
    z-index: 400;
    text-align: right;
}

.staff-m1 .staff-member .staff-photo .staff-title {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #262626;
    background-color: #e5e5e5;
    font-size: 13px;
    font-weight: 600;
    padding: 2px 10px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
}

.staff-m1 .staff-member .staff-info {
    padding: 15px;
    border-bottom: 3px solid #0083AE;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-top: 1px solid #E5E5E5;
    background-color: #fff;
}

.staff-m1 .staff-member .staff-info .staff-phone {
    font-size: 16px;
    font-weight: 200;
}

.staff-m1 .staff-member .staff-info .staff-phone i {
    color: #0083ae;
}

.staff-m1 .staff-member .staff-info p {
    color: #595959;
}


.staff-m1 .staff-member .staff-photo img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.staff-m1 .staff-row {
    margin-bottom: 25px;
}

.staff-m1 .staff-info a {
    margin-top: 10px;
    word-wrap: break-word;
}
