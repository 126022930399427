/*===================================================================================================
  Global Features
===================================================================================================*/
body {
    font-weight: 400;
}

.price, .find, .about, .seonamedlinks-standard {
    margin: 15px 0 15px 0
}

/* Links & Buttons
===========================================================*/
a {
    text-decoration: none;
    font-weight: 400;
}    

.btn-default,
.btn-default:focus {
    border: 1px solid;
}

.btn-default:hover,
.btn-default:active,
.btn-default.active {
    border: 1px solid;
}

.btn-default:disabled,
.btn-default.disabled {
    background-color: #E6E6E6;
}

.btn-primary,
.btn-primary:focus {
    border: 1px solid;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active {
    border: 1px solid;
}

.btn-link,
.btn-link:focus {
    color: #0083ae;
    text-decoration: none;
    border-bottom: 1px solid #0083ae;
}

.btn-link:hover,
.btn-link:active,
.btn-link.active {
    color: #00698c;
    text-decoration: none;
    border-bottom-color: #00698c;
}

.btn-link-color1,
.btn-link-color1:focus {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid transparent;
}

.btn-link-color1:hover,
.btn-link-color1:active,
.btn-link-color1.active {
    color: #fff;
    text-decoration: none;
    border-bottom-color: #fff;
}

.btn-link-primary,
.btn-link-primary:focus {
    text-decoration: none;
    text-transform: uppercase;
}

.btn-link-primary:hover,
.btn-link-primary:active,
.btn-link-primary.active {
    color: #DB990D;
    text-decoration: none;
}

.btn-super {
    height: 50px;
}




/* Modal
===========================================================*/

.modal-heading,
.modal-header {

    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .04em;
    border-bottom: 1px solid #ddd;
}




/* Modal Success
===========================================================*/
.modal-success {
    border-bottom: 0;
}

.modal-success h4 {
    font-weight: 400;
    text-transform: none;
}

/* Section Module Padding
===========================================================*/
.welcome-m1,
.h-inventory-carouselcompact-m1,
.h-find-m1,
.h-about-m1 {
    padding: 50px 0;
}
.locations-m1 {
    padding: 10px 0;
}


/* Sections Titles
===========================================================*/

.section-heading {
    padding-bottom: 30px;
}

.section-title {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02em;
}

.section-subheading {
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 300;
    margin-top: 10px;
}

.section-heading .section-title-line {
    display: block;
    width: 60px;
    height: 4px;
    margin-top: 10px;
}


/* Sections Titles
===========================================================*/
.special {
    text-decoration: line-through;
    font-size: 16px;
}
