
.h-find-m1 {
    padding: 2em 4em;
}

.h-find-m1 label { /*sr-only css*/
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@media (max-width: 991px) {
    .h-find-m1 label {
        display: block;
        color: #fff;
    }
}

@media (max-width: 767px) {
    .h-find-m1 {
        padding: 3em 2em;
    }
}