
/* Featured
===========================================================*/

#inventory {
    margin-bottom: 10px;
}

.h-inventory-carouselcompact-m1 {
	padding: 50px 0;
}

.h-inventory-carouselcompact-m1 .data-carouselshowmanymoveone {
    margin-bottom: 40px;
}


.h-inventory-carouselcompact-m1 .carousel-inner {
    width: 100%;
    overflow: hidden;
    position: relative;
    clear: both;
}

.h-inventory-carouselcompact-m1 .carousel-control {
    width: 4%;
    opacity: 1;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.h-inventory-carouselcompact-m1 .carousel-control.left,
.h-inventory-carouselcompact-m1 .carousel-control.right {
    margin-left: 0;
    margin-right: 0;
    background-image: none;
}

.h-inventory-carouselcompact-m1 .carousel-control .glyphicon-chevron-left,
.h-inventory-carouselcompact-m1 .carousel-control .glyphicon-chevron-right,
.h-inventory-carouselcompact-m1 .carousel-control .icon-next,
.h-inventory-carouselcompact-m1 .carousel-control .icon-prev {
    width: 60px;
    height: 60px;
    margin-top: -15px;
    font-size: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 58px;
    background-color: rgba(255,255,255, 0.5);
    color: #595959;
    z-index: 55000;
}

@media(max-width: 768px) {
    .h-inventory-carouselcompact-m1 .carousel-control .glyphicon-chevron-left,
    .h-inventory-carouselcompact-m1 .carousel-control .glyphicon-chevron-right,
    .h-inventory-carouselcompact-m1 .carousel-control .icon-next,
    .h-inventory-carouselcompact-m1 .carousel-control .icon-prev {
        width: 45px;
        height: 45px;
        font-size: 25px;
        line-height: 45px;
    }
}

.h-inventory-carouselcompact-m1 .extra1,
.h-inventory-carouselcompact-m1 .extra2,
.h-inventory-carouselcompact-m1 .extra3 {
    display: none;
}

@media only screen and (min-width: 768px) and (-webkit-transform-3d) {
    .h-inventory-carouselcompact-m1 .carousel-item.active.left,
    .h-inventory-carouselcompact-m1 .carousel-item.prev {
        transform: translate3d(-50%, 0, 0);
        left: 0;
    }

    .h-inventory-carouselcompact-m1 .carousel-item.active.right,
    .h-inventory-carouselcompact-m1 .carousel-item.next {
        transform: translate3d(50%, 0, 0);
        left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

    .h-inventory-carouselcompact-m1 .active.left,
	.h-inventory-carouselcompact-m1 .prev {
        left: -50%;
    }

    .h-inventory-carouselcompact-m1 .active.right,
	.h-inventory-carouselcompact-m1 .next {
        left: 50%;
    }

    .h-inventory-carouselcompact-m1 .extra1 {
        display: block;
    }
}

@media only screen and (min-width: 993px) and (-webkit-transform-3d) {
    .h-inventory-carouselcompact-m1 .carousel-item.active.left,
    .h-inventory-carouselcompact-m1 .carousel-item.prev {
        transform: translate3d(-25%, 0, 0);
        left: 0;
    }

    .h-inventory-carouselcompact-m1 .carousel-item.active.right,
    .h-inventory-carouselcompact-m1 .carousel-item.next {
        transform: translate3d(25%, 0, 0);
        left: 0;
    }
}

@media only screen and (min-width: 993px) {

    .h-inventory-carouselcompact-m1 .active.left,
	.h-inventory-carouselcompact-m1 .prev {
        left: -25%;
    }

    .h-inventory-carouselcompact-m1 .active.right,
	.h-inventory-carouselcompact-m1 .next {
        left: 25%;
    }

	.h-inventory-carouselcompact-m1 .extra1,
    .h-inventory-carouselcompact-m1 .extra2,
    .h-inventory-carouselcompact-m1 .extra3 {
        display: block;
    }
}



/* Homepage Vehicle Cards
===========================================================*/

/* Common style */
.h-inventory-carouselcompact-m1 .vehicle-photo {
	position: relative;
	height: 371px;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #000;
}

.h-inventory-carouselcompact-m1 .grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    /*background: #000;*/
    text-align: center;
    cursor: pointer;
	width: 98%;
}

.h-inventory-carouselcompact-m1 .grid figure img {
    position: relative;
    display: block;
    max-width: 100%;
    opacity: 0.8;
}

.h-inventory-carouselcompact-m1 .grid figure figcaption {
    padding: 2em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.h-inventory-carouselcompact-m1 .grid figure figcaption::before,
.h-inventory-carouselcompact-m1 .grid figure figcaption::after {
    pointer-events: none;
}

.h-inventory-carouselcompact-m1 .grid figure figcaption,
.h-inventory-carouselcompact-m1 .grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.h-inventory-carouselcompact-m1 .grid figure figcaption > a {
    /*z-index: 1000;*/
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.h-inventory-carouselcompact-m1 .grid figure h2 {
    word-spacing: -0.15em;
    font-weight: 300;
}

.h-inventory-carouselcompact-m1 .grid figure h2 span {
    font-weight: 800;
}

.h-inventory-carouselcompact-m1 .grid figure h2,
.h-inventory-carouselcompact-m1 .grid figure p {
    margin: 0;
}

.h-inventory-carouselcompact-m1 .grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
}



/*---------------*/
/***** Lily Hover Effect *****/
/*---------------*/

.h-inventory-carouselcompact-m1 figure.effect-lily img {
    max-width: 100%;
    width: -webkit-calc(100% + 50px);
    width: calc(100% + 50px);
    opacity: 0.5;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-40px,0, 0);
    transform: translate3d(-40px,0,0);
}

.h-inventory-carouselcompact-m1 figure.effect-lily figcaption {
    text-align: left;
}

.h-inventory-carouselcompact-m1 figure.effect-lily figcaption > div {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
}

.h-inventory-carouselcompact-m1 figure.effect-lily h2,
.h-inventory-carouselcompact-m1 figure.effect-lily p {
    -webkit-transform: translate3d(0,40px,0);
    transform: translate3d(0,40px,0);
}

.h-inventory-carouselcompact-m1 figure.effect-lily h2 {
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    font-size: 24px;
}

.h-inventory-carouselcompact-m1 figure.effect-lily p {
    opacity: 0;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
    transition: opacity 0.2s, transform 0.35s;
}

.h-inventory-carouselcompact-m1 figure.effect-lily:hover img,
.h-inventory-carouselcompact-m1 figure.effect-lily:hover p {
    opacity: 1;
}

.h-inventory-carouselcompact-m1 figure.effect-lily:hover h2 {
    font-size: 20px;
}

.h-inventory-carouselcompact-m1 figure.effect-lily:hover img,
.h-inventory-carouselcompact-m1 figure.effect-lily:hover h2,
.h-inventory-carouselcompact-m1 figure.effect-lily:hover p {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.h-inventory-carouselcompact-m1 figure.effect-lily:hover p {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
}

/* TODO: I think this styling might be redundant but I'm too scared to change it */
.h-inventory-carouselcompact-m1 .no-vehicles-found {
    width: 500px;
    max-width: 100%;
    background-color: #ffcccc;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
}