.header .simpleheader-m1 {
    padding: 100px;
    text-align: center;
}


.simple-header .simpleheader-m1 {
    padding: 100px;
    text-align: center;
}

.simple-header .simpleheader-m1 .header-section {
    padding: 15px 25px;
    text-align: left;
}

.simple-header .simpleheader-m1 .header-section .header-name {
    font-size: 40px;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.simple-header .simpleheader-m1 .header-name span {
    font-weight: 400;
    display: block;
    font-size: 17px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
}


.simple-header .simpleheader-m1 .header-section p.header-phone {
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .simple-header .simpleheader-m1 {
        display: none;
    }
}

.simple-header .simpleheader-m1 .img-logo {
    display: block;
    max-width: 600px;
    max-height: 150px;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
